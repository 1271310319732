import { z } from "zod";
import { TimeRangeSchema } from "../../schemas";
import { cloudTypesWithOther } from "../cloudType";

export const billingModelTypes = [
  "Pay as you Go",
  "Subscription",
  "Trial",
  "Not Found",
  "subscription",
] as const;

const BillingModelTypesEnum = z.enum(billingModelTypes);

export const marketplaceTypes = [
  "AWS Marketplace",
  "GCP Marketplace",
  "Azure Marketplace",
  "Direct",
  "Marketing Site",
  "Palo Alto Networks Marketplace",
  "PANW Web",
] as const;

const MarketplaceTypesEnum = z.enum(marketplaceTypes);

export const licenseTypes = ["business", "enterprise", "not_found"] as const;

export const LicenseTypesEnum = z.enum(licenseTypes);

export const supportTypes = ["standard", "premium_support"] as const;

export const SupportTypesEnum = z.enum(supportTypes);

export const activePlanTypes = [
  "RS_STANDARD",
  "RS_FOUNDATION",
  "RS_ADVANCED",
] as const;

export const ActivePlanTypesEnum = z.enum(activePlanTypes);

export const graphViewType = ["overall", "cloudTypes"] as const;

export const GraphViewTypeEnum = z.enum(graphViewType);

export const cloudTypesWithRepositories = [
  ...cloudTypesWithOther,
  "repositories",
  "others",
] as const;

export const CloudTypesWithRepositoriesEnumSchema = z.enum(
  cloudTypesWithRepositories,
);
export type CloudTypesWithRepositories = z.infer<
  typeof CloudTypesWithRepositoriesEnumSchema
>;

export const LicenseFilterSchema = z.object({
  accountIds: z
    .array(z.string())
    .optional()
    .transform((val) => val ?? []),
  accountGroupIds: z
    .array(z.string())
    .optional()
    .transform((val) => val ?? []),
  cloudTypes: z.array(CloudTypesWithRepositoriesEnumSchema),
  timeRange: TimeRangeSchema,
});
export type FilterOptions = z.infer<typeof LicenseFilterSchema>;

export const GetLicenseResponse = z.object({
  activePlanStartTs: z.number(),
  activePlanType: ActivePlanTypesEnum,
  billingModel: BillingModelTypesEnum,
  billingType: z.string().nullish(),
  buyNowUrl: z.string().nullish(),
  customerSupportID: z.string().nullish(),
  endTs: z.number(),
  isLicenseRenewed: z.boolean().nullish(),
  licenseType: LicenseTypesEnum,
  marketplace: MarketplaceTypesEnum,
  msspManaged: z.boolean(),
  msspSynthetic: z.boolean(),
  prismaId: z.string(),
  products: z.string().array().nullish(),
  renewedEndTime: z.number().nullish(),
  renewedStartTime: z.number().nullish(),
  renewedWorkloads: z.number().nullish(),
  serialNumber: z.string(),
  sku: z.string().nullish(),
  startTs: z.number(),
  subscriptionUrl: z.string().nullish(),
  supportType: SupportTypesEnum,
  tenantId: z.string(),
  tenantName: z.string(),
  twistlockUrl: z.string().nullish(),
  workloads: z.number(),
});

export const ColumnValueSchema = z.union([z.number(), z.undefined()]);

export const LicenseRequirementsFiltersSchema = z.object({
  "account.group": z.array(z.string()).optional(),
  "cloud.account": z.array(z.string()).optional(),
  "cloud.type": z.array(CloudTypesWithRepositoriesEnumSchema),
  timeRange: TimeRangeSchema,
});

export type LicenseFilters = z.infer<typeof LicenseRequirementsFiltersSchema>;

export const LicenseConsumptionCount = z
  .object({
    cdem: z.number(),
    container: z.number(),
    s3: z.number(),
    micro_segmentation: z.number(),
    iam: z.number(),
    container_caas: z.number(),
    agentless_host: z.number(),
    host: z.number(),
    serverless: z.number(),
    serverless_function_scans: z.number(),
    iaas: z.number(),
    vcg: z.number(),
    waas: z.number(),
    data_store: z.number(),
    agentless_container: z.number(),
    cloud_code_security: z.number(),
    total: z.number(),
    microseg_container: z.number(),
    microseg_host: z.number(),
    iaas_vm_count_onboarded: z.number(),
    iaas_vm_count_non_onboarded: z.number(),
    iaas_other_billable_count: z.number(),
    agentless_scanning: z.number(),
    cas_cicd_security: z.number(),
    data: ColumnValueSchema,
    azure_blob_storage: ColumnValueSchema,
    ccs_iac_developer: ColumnValueSchema,
    ccs_sca: ColumnValueSchema,
    ccs_secret_scanning: ColumnValueSchema,
    developers: ColumnValueSchema,
    iac: ColumnValueSchema,
    advanced: ColumnValueSchema,
    foundation: ColumnValueSchema,
  })
  .partial();

export const LicenseConsumptionTableRequestSchema = z.object({
  accountIds: z.array(z.string()),
  accountGroupIds: z.array(z.string()),
  cloudTypes: z.array(z.string()),
  timeRange: TimeRangeSchema,
  limit: z.number(),
  pageToken: z.string().optional(),
});

export const LicenseConsumptionTableResponseSchema = z.object({
  nextPageToken: z.union([z.string(), z.null()]),
  planType: z.enum(activePlanTypes),
  stats: LicenseConsumptionCount,
  totalRows: z.number(),
  items: z.array(
    z.object({
      account: z.object({
        name: z.string(),
        id: z.string().optional(),
      }),
      cloudType: z.enum(cloudTypesWithRepositories),
      resourceTypeCount: LicenseConsumptionCount,
      total: z.number(),
    }),
  ),
});
export type RunTimeData = {
  pageParam?: [];
  pages: z.infer<typeof LicenseConsumptionTableResponseSchema>[];
};

export const TimeSeriesTimeRange = z.object({
  startTime: z.number(),
  endTime: z.number(),
});

export const TimeSeriesCounts = z
  .object({
    aws: LicenseConsumptionCount,
    alibaba_cloud: LicenseConsumptionCount,
    gcp: LicenseConsumptionCount,
    azure: LicenseConsumptionCount,
    ibm: LicenseConsumptionCount,
    oci: LicenseConsumptionCount,
    repositories: LicenseConsumptionCount,
    others: LicenseConsumptionCount,
  })
  .partial();

export const DataPoints = z.object({
  timeRange: TimeSeriesTimeRange,
  counts: TimeSeriesCounts,
});

export const GetTimeSeriesResponse = z.object({
  dataPoints: z.array(DataPoints),
  workloadsPurchased: z.number().nonnegative(),
  timeUnit: z.string(),
  availableAsOf: z.number(),
});

export const UsageItem = z.object({
  account: z.object({
    name: z.string(),
    id: z.string(),
  }),
  cloudType: CloudTypesWithRepositoriesEnumSchema,
  total: z.number(),
  resourceTypeCount: LicenseConsumptionCount.partial(),
});

export const GetLicenseUsageResponse = z.object({
  items: z.array(UsageItem),
  nextPageToken: z.string().nullable(),
  planType: z.string(),
  stats: LicenseConsumptionCount,
  totalRows: z.number(),
});

export type LicenseUsage = z.infer<typeof GetLicenseUsageResponse>;

export type NewFilterOptions = z.infer<typeof LicenseFilterSchema> & {
  limit: number;
  pageToken: string;
};

export const loadedDataPropsSchema = z.object({
  account: z.object({
    name: z.string().optional(),
    id: z.string().optional(),
  }),
  cloudType: z.enum(cloudTypesWithRepositories),
  resourceTypeCount: LicenseConsumptionCount,
  total: z.number(),
});

export const ProcessTableDataReturnTypeSchema = z.object({
  loadedData: z.array(loadedDataPropsSchema),
  stats: LicenseConsumptionCount,
  showFooter: z.boolean(),
  activePlanType: z.string(),
});
export type ProcessedData = z.infer<typeof ProcessTableDataReturnTypeSchema>;

export type tableRowSchema = {
  id: string;
  original: z.infer<typeof UsageItem>;
};

export const OptimizeSubscriptionDataSchema = z.object({
  rs_standard: LicenseConsumptionCount,
  rs_foundation: LicenseConsumptionCount,
  rs_advanced: LicenseConsumptionCount,
  asset_count: LicenseConsumptionCount,
});

export const OptimizeSubscriptionResponseSchema = z.object({
  timeRange: z.union([
    TimeRangeSchema,
    z.object({ startTime: z.number(), endTime: z.number() }),
  ]),
  planType: z.string(),
  resourceTypeCount: OptimizeSubscriptionDataSchema,
});

export const licenseFileDownloadHelperRequestSchema = z.object({
  accountIds: z.array(z.any()),
  cloudType: z.string(),
  accountGroupIds: z.array(z.string()),
  timeRange: TimeRangeSchema,
  codeSecurity: z.boolean(),
  nonOnboardedCloudAccountResources: z.boolean(),
  csvHeaderRequired: z.boolean(),
});

export const cloudAccountNameResponseSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
    cloudType: CloudTypesWithRepositoriesEnumSchema,
    parentAccountName: z.string().optional(),
  }),
);

export const repositoriesSchema = z.array(
  z.object({
    id: z.string(),
  }),
);
