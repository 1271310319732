import { z } from "zod";
import {
  CloudTypesEnumSchema,
  CloudTypesWithOtherEnumSchema,
} from "../cloudType";
import { findingTypesSchema } from "../findingTypesSchema";
import { SastPolicyValidation } from "./sastSchemas";

export const PolicyCodeCategory = [
  "sast",
  "iac",
  "secrets",
  "licenses",
] as const;
export const SnippetStatus = ["PASSED", "FAILED"] as const;

export const Frameworks = [
  "Terraform",
  "CloudFormation",
  "Kubernetes",
  "Bicep",
  "ARM",
] as const;

export const policyTypeMapping = {
  incidents: ["anomaly", "network", "workload_incident"],
  attack_path: ["attack_path"],
  exposures: ["network"],
  misconfigurations: ["config"],
  identity: ["iam"],
  data: ["data"],
} as const;

export const incidentPolicySubtypeMap = {
  anomaly: ["network", "ueba", "dns"],
  audit_event: ["audit"],
  attack_path: [],
  config: [],
  iam: [],
  data: [],
  network: ["network_event"],
  workload_incident: ["run"],
} as const;

export const policyClass = [
  "bots",
  "behavioral",
  "exposure",
  "network_protection",
  "privileged_activity_monitoring",
  "runtime_incident",
  "vulnerabilities",
  "workload_detection",
  "web_attacks",
] as const;
export const PolicyClassEnumSchema = z.enum(policyClass).or(z.string());

export const policyCategory = ["incident", "risk"] as const;
export const PolicyCategoryEnumSchema = z.enum(policyCategory).or(z.string());
export type PolicyCategory = z.infer<typeof PolicyCategoryEnumSchema>;

export const policyMode = ["redlock_default", "custom"] as const;
export const PolicyModeEnumSchema = z.enum(policyMode);
export type PolicyModeType = z.infer<typeof PolicyModeEnumSchema>;

export const policyBuildType = ["cft", "k8s", "tf"] as const;
export const PolicyBuildTypeEnumSchema = z.enum(policyBuildType);

export const policyTypes = [
  "anomaly",
  "api",
  "attack_path",
  "audit_event",
  "config",
  "data",
  "iam",
  "network",
  "workload_vulnerability",
  "workload_incident",
  "waas_event",
  "malware",
  "grayware",
] as const;

export const PolicyTypesEnumSchema = z.enum(policyTypes).or(z.string());

export type PolicyType = z.infer<typeof PolicyTypesEnumSchema>;

export const assetClassTypes = [
  "code",
  "compute",
  "database",
  "identityAndSecurity",
  "network",
  "other",
  "kubernetes",
  "storage",
  "applicationAndContentDelivery",
  "managementAndGovernance",
  "analytics",
  "aiAndMachineLearning",
] as const;

export type AssetClassType = (typeof assetClassTypes)[number];

export const policySubTypes = [
  "run",
  "event",
  "misconfig",
  "misconfig_and_event",
  "build",
  "audit",
  "data_classification",
  "dns",
  "identity",
  "malware",
  "network",
  "network_config",
  "network_event",
  "permissions",
  "run_and_build",
  "ueba",
  "identity",
  "known_bots",
  "injections",
  "virtual_patches",
  "vulnerability_scanning",
  "shellshock",
  "internet_exposure",
  "sensitive_data_exposure",
  "unknown_bots",
  "event",
  "host",
  "container_image",
] as const;

export const PolicySubTypesEnumSchema = z.enum(policySubTypes).or(z.string());

export type PolicySubType = z.infer<typeof PolicySubTypesEnumSchema>;

export const policyModes = ["redlock_default", "custom"] as const;
export const PolicyModesEnumSchema = z.enum(policyModes);

export type PolicyMode = z.infer<typeof PolicyModesEnumSchema>;

export const severities = [
  "critical",
  "high",
  "medium",
  "low",
  "informational",
] as const;
export type Severity = (typeof severities)[number];

export const SeverityEnumSchema = z.enum(severities);

export const PolicyFilterSchema = z
  .object({
    "cloud.type": z.array(CloudTypesEnumSchema),
    "policy.category": z.array(PolicyCategoryEnumSchema),
    "policy.class": z.array(PolicyClassEnumSchema),
    "policy.complianceStandard": z.array(z.string()),
    "policy.complianceRequirement": z.array(z.string()),
    "policy.complianceSection": z.array(z.string()),
    "policy.enabled": z.enum(["true", "false"]),
    "policy.label": z.array(z.string()),
    "policy.name": z.array(z.string()),
    "policy.policyMode": z.array(PolicyModeEnumSchema),
    "policy.remediable": z.enum(["true", "false"]),
    "policy.severity": z.array(SeverityEnumSchema),
    "policy.subtype": z.array(PolicySubTypesEnumSchema),
    "policy.type": z.array(PolicyTypesEnumSchema),
    "resource.type": z.array(z.string()),
  })
  .partial();

export const ComplianceMetadataSchema = z.object({
  standardName: z.string().optional(),
  requirementName: z.string().optional(),
  requirementId: z.string().optional(),
  sectionId: z.string().optional(),
  sectionDescription: z.string().optional(),
  standardDescription: z.string().optional(),
  customAssigned: z.boolean().optional(),
  complianceId: z.string().optional(),
});

export const DataCriteriaSchema = z.object({
  classificationResult: z.string().optional(),
  exposure: z.string().optional(),
  extension: z.array(z.string()).optional(),
});

export const RuleChildrenSchema = z.array(
  z
    .object({
      name: z.string().optional(),
      criteria: z.string().optional(),
      type: z.string().optional(),
      metadata: z
        .object({
          checkovId: z.string().optional(),
          code: z.string().optional(),
          ruleName: z.string().optional(),
          granterAlertsEnabled: z.boolean().optional(),
        })
        .optional(),
      parameters: z
        .object({
          savedSearch: z.string().optional(),
        })
        .optional(),
      recommendation: z.string().optional(),
    })
    .optional(),
);

export const PolicySchema = z.object({
  policyId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  policyType: z.string(),
  policySubTypes: z.array(z.string()).optional(),
  systemDefault: z.boolean().optional(),
  severity: SeverityEnumSchema.optional(),
  apiName: z.string().optional(),
  findingTypes: z.array(z.string().nullish()).nullish(),
  rule: z
    .object({
      criteria: z.string().optional(),
      dataCriteria: DataCriteriaSchema.optional(),
      parameters: z
        .object({
          withIac: z.string().optional(),
          savedSearch: z.string().optional(),
        })
        .optional(),
      type: z.string().optional(),
      name: z.string().optional(),
      metadata: z
        .object({
          type: z.string().optional(),
          granterAlertsEnabled: z.boolean().optional(),
        })
        .optional(),
      children: RuleChildrenSchema.optional(),
    })
    .optional(),
  cloudType: CloudTypesWithOtherEnumSchema.optional(),
  complianceMetadata: z
    .array(ComplianceMetadataSchema.optional())
    .optional()
    .nullish(),
  enabled: z.boolean().optional(),
  readOnly: z.boolean().optional(),
  enableActionBanned: z.boolean().optional(),
  createdOn: z.number().optional(),
  createdBy: z.string().optional(),
  lastModifiedBy: z.string().optional(),
  lastModifiedOn: z.number().optional(),
  ruleLastModifiedOn: z.number().optional(),
  labels: z.array(z.string().optional()).optional(),
  policyCategory: z.string().optional(),
  policyClass: z.string().optional(),
  deleted: z.boolean().optional(),
  remediable: z.boolean().optional(),
  policyMode: z.string().optional(),
  policyUpi: z.string().optional(),
  recommendation: z.string().optional(),
  remediation: z
    .object({
      actions: z.array(z.object({ payload: z.string() })).optional(),
      dataRemediation: z.string().optional(),
      cliScriptTemplate: z.string().optional(),
      description: z.string().optional(),
      impact: z.string().optional(),
    })
    .optional(),
  resourceType: z.string().optional(),
});

export const GetPoliciesRequestSchema = z.object({
  slimView: z.enum(["true", "false"]).optional(),
  detailedComplianceMappings: z.union([z.literal("true"), z.literal("false")]),
  excludeCompliance: z.enum(["true", "false"]).optional(),
  "policy.type": PolicyTypesEnumSchema.optional(),
  "policy.subtype": PolicySubTypesEnumSchema.optional(),
  "policy.policyMode": PolicyModesEnumSchema.optional(),
  "policy.remediable": z.enum(["true", "false"]).optional(),
});

export const PoliciesResponseSchema = z.array(PolicySchema);
export type PoliciesResponseType = z.infer<typeof PoliciesResponseSchema>;

export const PolicySeverityResponse = z.object({
  "policy.severity": z.object({
    options: z.array(SeverityEnumSchema),
  }),
});

export const PolicySubtypeResponse = z.object({
  "policy.subtype": z.object({
    options: z.array(PolicySubTypesEnumSchema),
  }),
});

export const PolicyTypeResponse = z.object({
  "policy.type": z.object({
    options: z.array(PolicyTypesEnumSchema),
  }),
});

export const PolicyDetailsSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  policyId: z.string().optional(),
  severity: z.string().or(z.object({ value: z.string() })),
  labels: z
    .array(z.string().optional())
    .or(z.array(z.object({ value: z.string() })).optional()),
  recommendation: z.string().optional(),
  policyType: z.string(),
  rule: z
    .object({
      children: RuleChildrenSchema.optional(),
    })
    .optional(),
  policySubTypes: z.array(z.string()),
});

export type PolicyDetailsType = z.infer<typeof PolicyDetailsSchema>;

export const PolicyRuleSchema = z.object({
  computeRulesRelativeRedirectPath: z.string(),
  rules: z.array(
    z.object({
      modified: z.string(),
      owner: z.string(),
      name: z.string(),
      collections: z
        .array(z.object({ description: z.string().optional() }))
        .optional(),
    }),
  ),
});
export const PolicyRulesSchema = z.array(PolicyRuleSchema);

export const PolicyComplianceItemSchema = z.object({
  standardId: z.string().optional(),
  standardName: z.string().optional(),
  standardDescription: z.string().optional(),
  requirementId: z.string().optional(),
  requirementName: z.string().optional(),
  sectionId: z.string().optional(),
  sectionDescription: z.string().optional(),
  complianceId: z.string().optional(),
  sectionViewOrder: z.number().optional(),
  requirementViewOrder: z.number().optional(),
  systemDefault: z.boolean().optional(),
  customAssigned: z.boolean().optional(),
});

export const PolicyComplianceResponseSchema = z.array(
  z.record(z.string(), z.array(PolicyComplianceItemSchema.optional())),
);

export const ResourceTypesSchema = z.object({
  data: z.record(
    z.string(),
    z.object({
      provider: z.string(),
      text: z.string(),
      arguments: z.array(z.string()),
      prismaResourceTypeId: z.number().optional(),
    }),
  ),
});

export const PoliciesInfoSchema = z.object({
  visualEditor: z.object({
    frameworks: z.record(z.enum(Frameworks), z.array(z.string())).optional(),
    benchamrks: z.array(z.string()).optional(),
    provider: z.record(z.string(), z.string()).optional(),
    category: z.record(z.string(), z.string()),
    attributeOperators: z.array(z.string()).optional(),
  }),
  codeEditor: z.object({
    frameworks: z.record(z.enum(Frameworks), z.array(z.string())),
    benchamrks: z.array(z.string()),
    provider: z.record(z.string(), z.string()).or(z.array(z.string())),
    category: z.record(z.string(), z.string()).or(z.array(z.string())),
    attributeOperators: z.array(z.string()),
    cwes: z.array(z.string()).optional(),
    owasp: z.array(z.string()).optional(),
    languages: z.array(z.string()).optional(),
  }),
});

const PolicyConfigSchema = z.object({
  name: z.string(),
  category: z.record(z.string(), z.string()),
});

const IaCPolicyConfigSchema = PolicyConfigSchema.extend({
  benchamrks: z.array(z.string()),
  provider: z.record(z.string(), z.string()),
  attributeOperators: z.array(z.string()),
  frameworks: z.record(z.string(), z.array(z.string())),
});

const SastPolicyConfigSchema = PolicyConfigSchema.extend({
  cwes: z.array(z.string()),
  owasp: z.array(z.string()),
  languages: z.array(z.string()),
});

const SecretsPolicyConfigSchema = PolicyConfigSchema;

const LicensesPolicyConfigSchema = PolicyConfigSchema.extend({
  licenseTypes: z.array(z.string()),
});

export const CategorizedPoliciesInfoSchema = z.object({
  visualEditor: z.object({
    iac: IaCPolicyConfigSchema,
    secrets: SecretsPolicyConfigSchema,
    licenses: LicensesPolicyConfigSchema,
  }),
  codeEditor: z.object({
    iac: IaCPolicyConfigSchema,
    secrets: SecretsPolicyConfigSchema,
    sast: SastPolicyConfigSchema,
  }),
});

const Condition = z.object({
  value: z.string().optional(),
  attribute: z.string(),
  operator: z.string(),
  cond_type: z.string(),
  resource_types: z.array(z.string()),
});

const OrCondition = z.object({
  or: z.array(Condition),
});

const AndCondition = z.object({
  and: z.array(z.union([OrCondition, Condition])),
});

export const PreviewBuildQueryRequest = z.object({
  policy: z
    .object({
      policy_preview: z
        .object({
          resource_types: z.array(z.string()),
          query: z.union([AndCondition, OrCondition, Condition]).optional(),
        })
        .optional(),
    })
    .optional(),
  repositories: z.array(z.string()).optional(),
});

export const PreviewBuildQueryResponse = z.object({
  data: z.array(
    z.object({
      createdBy: z.string(),
      customerName: z.string(),
      source: z.string(),
      awsAccountId: z.string().nullish(),
      status: z.string(),
      resource: z.string(),
      arn: z.string(),
      lines: z.array(z.number()),
      file_path: z.string(),
      code: z.string().nullish(),
    }),
  ),
});

export const TestSnippetsByPolicyRequest = z.object({
  category: z.enum(PolicyCodeCategory),
  snippets: z.array(
    z.object({
      id: z.number(),
      code: z.string(),
      isViolating: z.boolean(),
    }),
  ),
  policy: SastPolicyValidation,
});

export const TestSnippetsByPolicyResponse = z.object({
  snippets: z.array(
    z.object({
      id: z.number(),
      status: z.enum(SnippetStatus),
      isViolating: z.boolean(),
      code: z.string(),
      errorLines: z.array(z.array(z.number())).nullable(),
    }),
  ),
});

export const ValidateBuildQueryRequest = z.object({
  metadata: z.object({
    category: z.string(),
    name: z.string(),
    severity: z.string(),
    guidelines: z.string(),
  }),
  scope: z.object({ provider: z.string() }),
  definition: z.object({
    or: z.array(
      z.object({
        cond_type: z.string(),
        resource_types: z.array(z.string()),
        attribute: z.string(),
        operator: z.string(),
        value: z.string(),
      }),
    ),
  }),
});

export const ValidateBuildQueryResponse = z.object({
  metadata: z.object({
    category: z.string(),
    name: z.string(),
    severity: z.string(),
    guidelines: z.string(),
  }),
  scope: z.object({ provider: z.string() }).optional(),
  definition: z
    .object({
      or: z.array(
        z.object({
          cond_type: z.string(),
          resource_types: z.array(z.string()),
          attribute: z.string(),
          operator: z.string(),
          value: z.string(),
        }),
      ),
    })
    .optional(),
});

export const AiRemediationStepsSchema = z.object({
  manualRemediation: z.object({
    title: z.string(),
    description: z.string(),
    instructions: z.string(),
  }),
  cli: z
    .array(
      z.object({
        description: z.string(),
        codeBlock: z.string(),
        impact: z.string(),
        automated: z.boolean(),
        title: z.string().optional(),
      }),
    )
    .optional(),
  tf: z
    .array(
      z.object({
        description: z.string(),
        codeBlock: z.string(),
        impact: z.string(),
        automated: z.boolean(),
      }),
    )
    .optional(),
});

export const AiRemediationSchema = z.object({
  value: z.array(
    z.object({
      policyId: z.string(),
      policyName: z.string(),
      cloudType: CloudTypesWithOtherEnumSchema,
      policyType: PolicyTypesEnumSchema,
      findingTypes: z.array(findingTypesSchema),
      aiRemediation: AiRemediationStepsSchema.nullable(),
      recommendation: z.string(),
      savedSearchId: z.string().nullable(),
      hasSearchExecutionSupport: z.boolean(),
    }),
  ),
});

export type AiRemediationStepsType = z.infer<typeof AiRemediationStepsSchema>;
export type AiRemediationResponseType = z.infer<typeof AiRemediationSchema>;
