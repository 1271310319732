import {
  ActionPlanJiraTicketStatus,
  ActionPlanNamesResponseSchema,
  ActionPlanRequestSchema,
  ActionPlanResponseSchema,
  ActionPlanSchema,
  ImpactedAssetsResponseSchema,
  NotificationResponseSchema,
  NotificationSchema,
  RecommendationSummaryResponseSchema,
  RelatedAlertsResponseSchema,
  SendActionPlanFeedbackRequestSchema,
  UpdateActionPlanStatusOrAssigneeRequestSchema,
  type ActionPlanFilterType,
  type ActionPlanRequestType,
  type NotificationType,
  type SendActionPlanFeedbackRequestType,
  type UpdateActionPlanStatusOrAssigneeRequestType,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";

export const actionPlanKeys = {
  all: [{ scope: "actionPlan" }] as const,
  actionPlanList: ({
    filters,
    sortBy,
  }: {
    filters: ActionPlanFilterType[] | [];
    sortBy: { name?: string; order?: string };
  }) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "actionPlanList",
        filters,
        sortBy,
      },
    ] as const,
  updateActionPlanStatusOrAssignee: ({
    status,
    assignee,
    actionPlanId,
  }: UpdateActionPlanStatusOrAssigneeRequestType) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "actionPlanStatusOrAssignee",
        status,
        assignee,
        actionPlanId,
      },
    ] as const,
  sendActionPlanFeedback: ({
    thumbsUpDown,
    thumbsDownReasons,
    thumbsDownDescription,
    actionPlanId,
  }: SendActionPlanFeedbackRequestType) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "actionPlanFeedback",
        thumbsUpDown,
        thumbsDownReasons,
        thumbsDownDescription,
        actionPlanId,
      },
    ] as const,
  relatedAlerts: ({ actionPlanId }: { actionPlanId: string }) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "relatedAlerts",
        actionPlanId,
      },
    ] as const,
  impactedAssets: ({ actionPlanId }: { actionPlanId: string }) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "impactedAssets",
        actionPlanId,
      },
    ] as const,
  actionPlanNames: () =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "actionPlanNames",
      },
    ] as const,
  recommendationSummary: ({ actionPlanId }: { actionPlanId: string }) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "recommendationSummary",
        actionPlanId,
      },
    ] as const,
  jiraStatus: ({
    actionPlanId,
    prismaId,
  }: {
    actionPlanId: string;
    prismaId: string;
  }) =>
    [
      {
        ...actionPlanKeys.all[0],
        entity: "jiraStatus",
        actionPlanId,
        prismaId,
      },
    ] as const,
} as const;

export const getAllActionPlans = async ({
  queryKey: [{ filters, sortBy }],
  pageParam: pageToken,
}: QueryFunctionContext<
  ReturnType<(typeof actionPlanKeys)["actionPlanList"]>,
  string
>) => {
  return jsonApi({
    path: "/apm/api/v1/action-plan",
    body: {
      limit: 25,
      ...(filters.length > 0 ? { filters } : {}),
      ...(sortBy.name && sortBy.order ? { sortBy } : {}),
      ...(pageToken && { nextPageToken: pageToken }),
    } as ActionPlanRequestType,
    config: { method: "post" },
    requestSchema: ActionPlanRequestSchema,
    responseSchema: ActionPlanResponseSchema,
  });
};

export const getRelatedAlerts = async ({
  queryKey: [{ actionPlanId }],
  pageParam,
}: QueryFunctionContext<
  ReturnType<(typeof actionPlanKeys)["relatedAlerts"]>,
  string
>) => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/${actionPlanId}/related-alerts`,
    params: {
      limit: "100",
      ...(pageParam ? { next_page_token: pageParam } : {}),
    },
    requestSchema: z.void(),
    responseSchema: RelatedAlertsResponseSchema,
  });
};

export const getImpactedAssets = async ({
  queryKey: [{ actionPlanId }],
  pageParam,
}: QueryFunctionContext<
  ReturnType<(typeof actionPlanKeys)["impactedAssets"]>,
  string
>) => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/${actionPlanId}/impacted-assets`,
    params: {
      limit: "100",
      ...(pageParam ? { next_page_token: pageParam } : {}),
    },
    requestSchema: z.void(),
    responseSchema: ImpactedAssetsResponseSchema,
  });
};

export const updateActionPlanStatusOrAssignee = async ({
  status,
  assignee,
  actionPlanId,
}: UpdateActionPlanStatusOrAssigneeRequestType) => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/${actionPlanId}/status-assignee`,
    body: {
      status,
      assignee,
    },
    config: {
      method: "PATCH",
    },
    requestSchema: UpdateActionPlanStatusOrAssigneeRequestSchema.omit({
      actionPlanId: true,
    }),
    responseSchema: ActionPlanSchema,
  });
};

export const sendActionPlanFeedback = async ({
  thumbsUpDown,
  thumbsDownReasons,
  thumbsDownDescription,
  actionPlanId,
}: SendActionPlanFeedbackRequestType) => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/${actionPlanId}/feedback`,
    body: {
      thumbsUpDown,
      thumbsDownReasons,
      thumbsDownDescription,
    },
    config: {
      method: "PATCH",
    },
    requestSchema: SendActionPlanFeedbackRequestSchema.omit({
      actionPlanId: true,
    }),
    responseSchema: ActionPlanSchema,
  });
};

export const getActionPlanNames = async () => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/names`,
    requestSchema: z.void(),
    responseSchema: ActionPlanNamesResponseSchema,
  });
};

export const getRecommendationSummary = async ({
  queryKey: [{ actionPlanId }],
}: QueryFunctionContext<
  ReturnType<(typeof actionPlanKeys)["recommendationSummary"]>
>) => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/${actionPlanId}/recommendation-summary`,
    requestSchema: z.void(),
    responseSchema: RecommendationSummaryResponseSchema,
  });
};

export const sendNotificationForActionPlan = async ({
  notificationReq,
  actionPlanId,
}: {
  notificationReq: NotificationType;
  actionPlanId: string;
}) => {
  return jsonApi({
    path: `/apm/api/v1/action-plan/${actionPlanId}/notification/ondemand`,
    config: {
      method: "post",
    },
    body: notificationReq,
    requestSchema: NotificationSchema,
    responseSchema: NotificationResponseSchema,
  });
};

export const getJiraStatus = async ({
  queryKey: [{ actionPlanId, prismaId }],
}: QueryFunctionContext<ReturnType<(typeof actionPlanKeys)["jiraStatus"]>>) => {
  return jsonApi({
    path: `api/v1/tenant/${prismaId}/ticket?findingKey=${actionPlanId}`,
    requestSchema: z.void(),
    responseSchema: ActionPlanJiraTicketStatus,
  });
};
