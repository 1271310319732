import { InfoCircleIcon } from "icons";
import { FormattedMessage, useIntl } from "react-intl";
import { Body, Button, Tooltip } from "ui";
import { useTableInstance } from "../TableContext";
import { ConnectedResultsSummary } from "./ConnectedResultsSummary";

export function InfiniteTableResultsSummary({
  fetchNextPage,
  hasNextPage,
  initiateAutoFetch,
  isFetchingNextPage,
  numLoaded,
  numRecords,
}: {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  initiateAutoFetch?: () => void;
  isFetchingNextPage: boolean;
  numLoaded: number;
  numRecords?: string;
}) {
  const {
    enablePagination,
    page,
    rows,
    state: { pageIndex, pageSize },
  } = useTableInstance();

  const pageRecord = pageSize * pageIndex;
  const totalRecords = rows.length;
  const startingRecord = pageRecord + 1;
  const endingRecord = enablePagination
    ? pageRecord + page.length
    : totalRecords;

  const allRecordsFetched = !hasNextPage || numLoaded === Number(numRecords);

  return (
    <div className="flex flex-col space-y-2">
      <div
        className="flex items-center space-x-2"
        data-testid="infiniteTableResults"
      >
        <Button
          disabled={allRecordsFetched}
          isLoading={isFetchingNextPage && !allRecordsFetched}
          onClick={() =>
            initiateAutoFetch ? initiateAutoFetch() : fetchNextPage()
          }
        >
          {initiateAutoFetch ? (
            <FormattedMessage
              defaultMessage="Show All"
              id="KNVtQO"
              description="Button label to load all data"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Load More"
              id="DO9YNt"
              description="Button label to load more data"
            />
          )}
        </Button>
        {hasNextPage ? (
          <>
            <Body>
              <FormattedMessage
                defaultMessage="Displaying {startingRecord, number} - {endingRecord, number} of {numLoaded, number}+ available records"
                id="FWvXUw"
                description="Summarize the current page and loading state of table data"
                values={{
                  endingRecord,
                  numLoaded,
                  numRecords,
                  startingRecord: Math.min(startingRecord, endingRecord),
                }}
              />
            </Body>
            <NumRecordsTooltip numRecords={numRecords} />
          </>
        ) : (
          <div className="flex items-center space-x-1">
            <ConnectedResultsSummary />
            {!!numLoaded && (
              <Body appearance="secondary" size="sm">
                <FormattedMessage
                  defaultMessage="(All records loaded)"
                  id="YAVqke"
                  description="Clarifies that all the available data has been loaded."
                />
              </Body>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const NumRecordsTooltip = ({ numRecords }: { numRecords?: string }) => {
  const intl = useIntl();

  return (
    <Tooltip
      label={
        Number(numRecords) >= 100 ? (
          <FormattedMessage
            defaultMessage="Due to the large number of total results ({numRecords}), only a subset has been loaded. Sorts and filters only apply to the data that is loaded on the page."
            id="zlBT0b"
            description="Clarifies that only a subset of data has been loaded on the page"
            values={{ numRecords }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Due to the large number of total results, only a subset has been loaded. Sorts and filters only apply to the data that is loaded on the page."
            id="VsOUTT"
            description="Clarifies that only a subset of data has been loaded on the page"
          />
        )
      }
    >
      <span>
        <InfoCircleIcon
          aria-label={intl.formatMessage({
            defaultMessage: "Info on total records",
            id: "RD2/hd",
            description: "Info on total records",
          })}
        />
      </span>
    </Tooltip>
  );
};
