import {
  AiRemediationSchema,
  PoliciesInfoSchema,
  PoliciesResponseSchema,
  PolicyDetailsSchema,
  PolicyRuleSchema,
  PolicySchema,
  PolicyTypeResponse,
  PreviewBuildQueryRequest,
  PreviewBuildQueryResponse,
  ResourceTypesSchema,
  TestSnippetsByPolicyRequest,
  TestSnippetsByPolicyResponse,
  type GetPoliciesRequestSchema,
  type ValidateBuildQueryRequest,
} from "./schemas";

import { type QueryFunctionContext } from "@tanstack/react-query";
import { z } from "zod";
import { jsonApi } from "../../apis";

export const policiesKeys = {
  all: [{ scope: "policies" }] as const,
  policies: ({
    slimView,
    detailedComplianceMappings,
    excludeCompliance,
    "policy.type": policyType,
    "policy.remediable": policyRemediable,
    "policy.subtype": policySubType,
    "policy.policyMode": policyMode,
  }: z.infer<typeof GetPoliciesRequestSchema>) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "policy",
        slimView,
        detailedComplianceMappings,
        excludeCompliance,
        policyType,
        policyRemediable,
        policySubType,
        policyMode,
      },
    ] as const,
  policy: ({ policyId }: { policyId: string }) =>
    [
      {
        ...policiesKeys.all[0],
        policyId,
      },
    ] as const,

  policySeverity: () =>
    [{ ...policiesKeys.all[0], entity: "policySeverity" }] as const,

  policySubtype: () =>
    [{ ...policiesKeys.all[0], entity: "policySubtype" }] as const,

  policyType: () => [{ ...policiesKeys.all[0], entity: "policyType" }] as const,
  policyRule: ({ policyId }: { policyId: string }) =>
    [{ ...policiesKeys.all[0], entity: "policyRule", policyId }] as const,
  label: () => [{ ...policiesKeys.all[0], entity: "label" }] as const,
  filterSuggest: () =>
    [
      {
        ...policiesKeys.all[0],
        entity: "filterSuggest",
      },
    ] as const,
  dataProfilesSuggest: () =>
    [{ ...policiesKeys.all[0], entity: "dataProfilesSuggest" }] as const,
  policyCompliance: () =>
    [{ ...policiesKeys.all[0], entity: "policyCompliance" }] as const,
  resourceTypes: () =>
    [{ ...policiesKeys.all[0], entity: "resourceTypes" }] as const,
  policiesInfo: ({
    enableBridgecrewPoliciesV2,
    enableSast,
  }: {
    enableBridgecrewPoliciesV2: boolean;
    enableSast: boolean;
  }) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "policiesInfo",
        enableBridgecrewPoliciesV2,
        enableSast,
      },
    ] as const,
  policiesSecrets: () =>
    [
      {
        ...policiesKeys.all[0],
        entity: "policiesSecrets",
      },
    ] as const,
  previewBuildQuery: ({
    query,
    enableBridgecrewPoliciesV2,
  }: {
    query: z.infer<typeof PreviewBuildQueryRequest>;
    enableBridgecrewPoliciesV2: boolean;
  }) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "previewBuildQuery",
        query,
        enableBridgecrewPoliciesV2,
      },
    ] as const,
  testSnippetsByPolicy: ({
    query,
  }: {
    query: z.infer<typeof TestSnippetsByPolicyRequest>;
  }) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "testSnippetsByPolicy",
        query,
      },
    ] as const,
  validateBuildQuery: ({
    query,
    enableBridgecrewPoliciesV2,
  }: {
    query: z.infer<typeof ValidateBuildQueryRequest>;
    enableBridgecrewPoliciesV2: boolean;
  }) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "validateBuildQuery",
        query,
        enableBridgecrewPoliciesV2,
      },
    ] as const,
  validateQueryToSaveAsPolicy: ({
    query,
    searchType,
  }: {
    query?: string;
    searchType?: string;
  }) =>
    [
      {
        entity: "validateQueryToSaveAsPolicy",
        query,
        searchType,
      },
    ] as const,
  policiesTranslate: ({
    query,
  }: {
    query: z.infer<typeof PreviewBuildQueryRequest>;
  }) =>
    [{ ...policiesKeys.all[0], entity: "policiesTranslate", query }] as const,
  validateCliScript: ({
    cliScriptTemplate,
    cloudType,
  }: {
    cliScriptTemplate: string;
    cloudType: string;
  }) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "policyRule",
        cliScriptTemplate,
        cloudType,
      },
    ] as const,
  aiRemediationSteps: ({
    policyId,
    unifiedAssetId,
    alertId,
  }: {
    policyId: string;
    unifiedAssetId: string;
    alertId: string;
  }) =>
    [
      {
        ...policiesKeys.all[0],
        entity: "aiRemediationSteps",
        policyId,
        unifiedAssetId,
        alertId,
      },
    ] as const,
};

export const getPolicies = async ({
  queryKey: [
    {
      slimView,
      detailedComplianceMappings,
      excludeCompliance,
      policyType,
      policyRemediable,
      policySubType,
      policyMode,
    },
  ],
}: QueryFunctionContext<ReturnType<(typeof policiesKeys)["policies"]>>) => {
  return jsonApi({
    path: "v2/policy",
    params: {
      // slimView is used to exclude some data from response to make is faster
      // https://redlock.atlassian.net/wiki/spaces/RED/pages/4295229579/WIP+Slim+list+Policies#Solution
      ...(slimView && { slimView: "true" }),
      detailedComplianceMappings,
      // TODO: Wrap query key in params key and destructure params.
      ...(excludeCompliance && { excludeCompliance: excludeCompliance }),
      ...(policyType && { ["policy.type"]: policyType }),
      ...(policyRemediable && { ["policy.remediable"]: policyRemediable }),
      ...(policySubType && { ["policy.subtype"]: policySubType }),
      ...(policyMode && { ["policy.policyMode"]: policyMode }),
    },
    requestSchema: z.void(),
    responseSchema: PoliciesResponseSchema,
  });
};

export const getPolicy = async ({
  queryKey: [{ policyId }],
}: QueryFunctionContext<ReturnType<(typeof policiesKeys)["policy"]>>) => {
  return jsonApi({
    path: `policy/${policyId}`,
    requestSchema: z.void(),
    responseSchema: PolicySchema,
  });
};

export const getPolicyType = async (
  _props: QueryFunctionContext<ReturnType<(typeof policiesKeys)["policyType"]>>,
) => {
  return jsonApi({
    path: "filter/policy/suggest",
    requestSchema: z.void(),
    responseSchema: PolicyTypeResponse,
  });
};

export const getPolicyRule = async ({
  queryKey: [{ policyId }],
}: QueryFunctionContext<ReturnType<(typeof policiesKeys)["policyRule"]>>) => {
  return jsonApi({
    path: `policy/${policyId}/compute/rule`,
    requestSchema: z.void(),
    responseSchema: PolicyRuleSchema,
  });
};

export const getLabel = async (
  _props: QueryFunctionContext<ReturnType<(typeof policiesKeys)["label"]>>,
) => {
  return jsonApi({
    path: `label`,
    requestSchema: z.void(),
    responseSchema: z.array(z.string()),
  });
};

export const editPolicy = async ({
  policyId,
  policyDetails,
}: {
  policyId: string;
  policyDetails: z.infer<typeof PolicyDetailsSchema>;
}) => {
  return jsonApi({
    path: `policy/${policyId}`,
    body: { ...policyDetails },
    config: {
      method: "put",
    },
    requestSchema: PolicyDetailsSchema,
    responseSchema: PolicyDetailsSchema,
  });
};

export const clonePolicy = async ({
  policyId,
  policyDetails,
}: {
  policyId: string;
  policyDetails: z.infer<typeof PolicyDetailsSchema>;
}) => {
  return jsonApi({
    path: `policy/${policyId}`,
    body: { ...policyDetails },
    config: {
      method: "post",
    },
    requestSchema: PolicyDetailsSchema,
    responseSchema: PolicyDetailsSchema,
  });
};

export const createPolicy = async ({
  policyDetails,
}: {
  policyDetails: z.infer<typeof PolicyDetailsSchema>;
}) => {
  return jsonApi({
    path: "policy",
    body: { ...policyDetails },
    config: {
      method: "post",
    },
    requestSchema: PolicyDetailsSchema,
    responseSchema: PolicyDetailsSchema,
  });
};

export const deletePolicy = async ({ policyId }: { policyId: string }) => {
  return jsonApi({
    path: `policy/${policyId}`,
    config: {
      method: "delete",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });
};

export const getDataProfilesSuggest = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof policiesKeys)["dataProfilesSuggest"]>
  >,
) => {
  return jsonApi({
    path: "dlp/api/v1/policy/dataprofiles/suggest",
    requestSchema: z.void(),
    responseSchema: z.array(z.string().optional()),
  });
};

export const getPolicyCompliance = async (
  _props: QueryFunctionContext<
    ReturnType<(typeof policiesKeys)["policyCompliance"]>
  >,
) => {
  return jsonApi({
    path: "policy/compliance",
    requestSchema: z.void(),
    // TODO: fix policy compliance response schema
    responseSchema: z.unknown(), //PolicyComplianceResponseSchema
  });
};

export const getResourceTypes = async () => {
  return jsonApi({
    path: "bridgecrew/api/v1/resources/types",
    requestSchema: z.void(),
    responseSchema: ResourceTypesSchema,
  });
};

export const getPoliciesInfo = async ({
  queryKey: [{ enableBridgecrewPoliciesV2, enableSast }],
}: QueryFunctionContext<ReturnType<(typeof policiesKeys)["policiesInfo"]>>) => {
  const params = {} as { includeCwes?: string };

  if (enableBridgecrewPoliciesV2 && enableSast) params["includeCwes"] = "true";

  return jsonApi({
    path: `bridgecrew/api/${
      enableBridgecrewPoliciesV2 ? "v2" : "v1"
    }/policies/getPoliciesInfo`,
    requestSchema: z.void(),
    responseSchema: PoliciesInfoSchema,
    params,
  });
};

export const getPreviewBuildQuery = async ({
  queryKey: [{ query, enableBridgecrewPoliciesV2 }],
}: QueryFunctionContext<
  ReturnType<(typeof policiesKeys)["previewBuildQuery"]>
>) => {
  return jsonApi({
    body: { ...query },
    path: `bridgecrew/api/${
      enableBridgecrewPoliciesV2 ? "v2" : "v1"
    }/policies/preview`,
    config: {
      method: "post",
    },
    requestSchema: PreviewBuildQueryRequest,
    responseSchema: PreviewBuildQueryResponse,
  });
};

export const testSnippetsByPolicyRequest = async (
  payload: z.infer<typeof TestSnippetsByPolicyRequest>,
) => {
  return jsonApi({
    body: payload,
    path: "bridgecrew/api/v2/policies/snippets",
    config: {
      method: "post",
    },
    requestSchema: TestSnippetsByPolicyRequest,
    responseSchema: TestSnippetsByPolicyResponse,
  });
};

export const validateBuildQuery = async ({
  queryKey: [{ query, enableBridgecrewPoliciesV2 }],
}: QueryFunctionContext<
  ReturnType<(typeof policiesKeys)["validateBuildQuery"]>
>) => {
  return jsonApi({
    body: { ...query },
    path: `bridgecrew/api/${
      enableBridgecrewPoliciesV2 ? "v2" : "v1"
    }/policies/definition/none`,
    config: {
      method: "post",
    },
    requestSchema: z.unknown(),
    responseSchema: z.unknown(),
  });
};

export const validateQueryToSaveAsPolicy = async ({
  queryKey: [{ query, searchType }],
}: QueryFunctionContext<
  ReturnType<(typeof policiesKeys)["validateQueryToSaveAsPolicy"]>
>) => {
  await jsonApi({
    body: {
      policyType: searchType,
      rule: {
        criteria: query,
      },
    },
    path: `policy/rule/validate`,
    config: {
      method: "post",
    },
    requestSchema: z.object({
      policyType: z.string(),
      rule: z.object({
        criteria: z.string(),
      }),
    }),
    responseSchema: z.void(),
  });
  return true;
};

export const enablePolicy = async ({
  policyId,
  enabled,
}: {
  policyId: string;
  enabled: boolean;
}) =>
  jsonApi({
    path: `policy/${policyId}/status/${enabled}`,
    config: {
      method: "PATCH",
    },
    requestSchema: z.void(),
    responseSchema: z.string(),
  });

export const validateCliScript = async ({
  cliScriptTemplate,
  cloudType,
}: {
  cliScriptTemplate: string;
  cloudType: string;
}) => {
  return jsonApi({
    body: { cliScriptTemplate, cloudType },
    path: `/alert/remediation/test`,
    config: {
      method: "post",
    },
    requestSchema: z.object({
      cliScriptTemplate: z.string(),
      cloudType: z.string(),
    }),
    responseSchema: z.boolean(),
  });
};

export const getAiRemediationSteps = async ({
  queryKey: [{ policyId, unifiedAssetId, alertId }],
}: QueryFunctionContext<
  ReturnType<(typeof policiesKeys)["aiRemediationSteps"]>
>) => {
  return jsonApi({
    path: `policy/api/v1/fetch/remediation/${policyId}`,
    params: {
      unifiedAssetId,
      alertId,
    },
    requestSchema: z.void(),
    responseSchema: AiRemediationSchema,
  });
};
