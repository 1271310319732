import { type MouseEventHandler, type ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";
import { type Severity } from "requests";
import { classNames } from "utils";
import { DynamicFormattedMessage } from "../Intl";
import { Tooltip } from "../Tooltip";
import { Body } from "../Typography";

type Props = {
  level: Severity;
  highlight?: boolean;
  count?: number | string | JSX.Element;
  /*
  countLabel - need for displaying count and adds the severity level label(we can use it If count is not underfined)
  */
  countLabel?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * By default, if the count is not zero, the label will render
   * the number in blue color, and will use cursor-pointer on hover
   * indicating it's clickable.
   * Use this prop to force the label to be unclickable and
   * revert the text to the default color.
   *
   * Note: We cannot decide whether it's clickable by looking at the `onClick` prop,
   * since the click handler might be registered to a parent component,
   * eg. a wrapping `RouterLink`.
   */
  disabled?: boolean;
  tooltipLabel?: string | ReactNode;
};

const severityMessages = defineMessages<Severity>({
  critical: {
    defaultMessage: "Critical",
    id: "tZ3zzZ",

    description: "Severity label",
  },
  high: {
    defaultMessage: "High",
    id: "b98uSz",

    description: "Severity label",
  },
  medium: {
    defaultMessage: "Medium",
    id: "UvadkP",

    description: "Severity label",
  },
  low: {
    defaultMessage: "Low",
    id: "af4NhU",

    description: "Severity label",
  },
  informational: {
    defaultMessage: "Info",
    id: "S3g3GG",

    description: "Severity label",
  },
});

const severityBackgroundColors = {
  critical: "bg-severity-critical dark:bg-severity-critical",
  high: "bg-severity-high dark:bg-severity-high",
  medium: "bg-severity-medium dark:bg-severity-medium",
  low: "bg-severity-low dark:bg-severity-low",
  informational: "bg-severity-informational dark:bg-severity-informational",
} satisfies Record<Severity, string>;

const severityBorderColors = {
  critical: "border-severity-critical dark:border-severity-critical",
  high: "border-severity-high dark:border-severity-high",
  medium: "border-severity-medium dark:border-severity-medium",
  low: "border-severity-low dark:border-severity-low",
  informational:
    "border-severity-informational dark:border-severity-informational",
} satisfies Record<Severity, string>;

export function SeverityLabel({
  level,
  count,
  highlight,
  countLabel,
  onClick,
  disabled,
  tooltipLabel,
}: Props) {
  const intl = useIntl();

  return (
    <Tooltip isVisible={!tooltipLabel ? false : undefined} label={tooltipLabel}>
      <span>
        <Body
          onClick={onClick}
          as="button"
          size="sm"
          disabled={disabled}
          addClassName={classNames(
            "inline-flex flex-row",
            "items-center",
            "h-[24px]",
            "gap-1 py-0.5 pl-0.5 pr-2",
            "rounded-[6px] border",
            "text-xs font-bold no-underline",
            highlight
              ? severityBorderColors[level]
              : "border-gray-300 dark:border-blue-steel-850",
            count && !disabled ? "text-link dark:text-dark-bg-link" : "",
          )}
          title={!tooltipLabel ? level : undefined}
          aria-label={level}
        >
          <span
            className={classNames(
              severityBackgroundColors[level],
              "h-[18px] w-[5px] rounded-l-[2.5px] rounded-r-[1.5px] pr-1",
            )}
          />
          {count !== undefined ? (
            <>
              {countLabel && (
                <>
                  <DynamicFormattedMessage
                    messageMap={severityMessages}
                    token={level}
                  />

                  <div
                    className={classNames(
                      "h-[24px] border-r border-gray-300 pl-1 dark:border-blue-steel-850",
                    )}
                  />
                </>
              )}
              <span className={classNames("w-full text-center")}>
                {typeof count === "number"
                  ? intl.formatNumber(count || 0, { notation: "compact" })
                  : count}
              </span>
            </>
          ) : (
            <DynamicFormattedMessage
              messageMap={severityMessages}
              token={level}
            />
          )}
        </Body>
      </span>
    </Tooltip>
  );
}
